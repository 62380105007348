import { Clipboard } from '@angular/cdk/clipboard';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'environment';
import saveAs from 'file-saver';
import * as JSZip from 'jszip';
import { ProductBasicInfo } from 'src/app/core/domain/products/product-detail.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetProductCreativesEligibilityUseCase } from 'src/app/core/usecases/products/get-creatives.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { CREATIVE_REQUEST_FEATURE, FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { featureAttributeAssign } from 'src/app/presentation/shared/utilities/feature-attribute-assign.utility';
import { isVideoFromUrl } from 'src/app/presentation/shared/utilities/is-video.utility';
import { removeImageSizeFromURL } from 'src/app/presentation/shared/utilities/remove-image-size.utility';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import { user } from '@features/user/data';
import { WEB_CUSTOM_LANDING_DESC } from 'app/presentation/shared/constants/feature-flags';
import {
  PRODUCT_CHECKOUT_LINK_DISABLE,
  PRODUCT_CHECKOUT_LINK_ENABLE,
  PRODUCT_COPY_CHECKOUT_LINK,
  PRODUCT_CREATE_CHECKOUT_LINK,
  PRODUCT_CREATE_CHECKOUT_LINK_ERROR,
  PRODUCT_REDIRECT_CHECKOUT_LINK,
  PRODUCT_UPDATE_CHECKOUT_LINK,
  PRODUCT_UPDATE_CHECKOUT_LINK_ERROR,
} from 'app/presentation/shared/constants/mixpanel';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import * as JSZipUtils from 'jszip-utils';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import {
  ProductCheckoutCreateModel,
  ProductCheckoutModel,
  ProductCheckoutUpdateModel,
} from 'src/app/core/domain/products/product-checkout';
import { CreateProductCheckoutUseCase } from 'src/app/core/usecases/products/create-product-checkout.usecase';
import { GetProductCheckoutDetailUseCase } from 'src/app/core/usecases/products/get-product-checkout-detail.usecase';
import { UpdateProductCheckoutUseCase } from 'src/app/core/usecases/products/update-product-checkout.usecase';
import { ProductCheckoutImagesComponent } from '../product-checkout-images/product-checkout-images.component';

@Component({
  selector: 'app-product-gallery',
  templateUrl: './product-details-gallery.component.html',
  styleUrls: ['./product-details-gallery.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    TranslateModule,
    FormsModule,
    MatSlideToggleModule,
    ProductCheckoutImagesComponent,
  ],
})
export class ProductDetailsGalleryComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() productGallery: string[];

  @Input() productUrl: string;

  @Input() hasMultipleVariants = false;

  @Input() basicInfo: ProductBasicInfo;

  @Output() downloadedImages: EventEmitter<void> = new EventEmitter();

  @Output() downloadedVideos: EventEmitter<void> = new EventEmitter();

  @Output() reviewProduct: EventEmitter<void> = new EventEmitter();

  @Output() customizeLanding: EventEmitter<boolean> = new EventEmitter();

  @Output() productCheckoutDetailEmit: EventEmitter<ProductCheckoutModel> = new EventEmitter();

  public mediaGallery: { url: string; isVideo: boolean }[];

  public productImgIdx = 0;

  public imgCarouselIdx = 0;

  public additionalImagesContainerWidth: number;

  public additionalImagesButtonSize: number;

  public thumbnailImagesTranslationOffset: number;

  public mediaGalleryHasVideos = false;

  public facebookShareLink: string;

  public whatsappShareLink: string;

  public assetsPath = '../../../../../assets/img/product-details-icons/';

  public isCreativesEnabled = false;

  public isProductCreativesAllowed = false;

  public shouldShowCustomCheckout = false;

  public checkoutAmount = 0;

  public landingStatus = false;

  public statusTranslationKey = '';

  public productCheckoutDetail: ProductCheckoutModel;

  public isCheckoutPriceButtonEnabled = false;

  public priceInputEnabled = false;

  public imageLinks: string[];

  public shouldShowProductCheckoutV2 = false;

  public isCheckoutLoading = false;

  private isInitialCall = false;

  private _getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);

  private _getFeatureAttributeUseCase = inject(GetFeatureAttributeUsecase);

  private _router = inject(Router);

  private _appURLs = inject(appUrlsConstantsInjectionToken);

  private _getProductCreativesEligibilityUseCase = inject(GetProductCreativesEligibilityUseCase);

  private _createProductCheckoutUseCase = inject(CreateProductCheckoutUseCase);

  private _getProductCheckoutDetailUseCase = inject(GetProductCheckoutDetailUseCase);

  private _updateProductCheckoutDetailUseCase = inject(UpdateProductCheckoutUseCase);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  private _checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);

  private _toastrService = inject(ToastrService);

  private _clipboard = inject(Clipboard);

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
    this._checkCheckoutEnabled();
    this.checkCreativesEnabled();
    this.getProductCreativesEligibility();
  }

  ngOnChanges(): void {
    this.rearrangeMediaGallery();
    this.facebookShareLink = `http://www.facebook.com/share.php?u=${this.productUrl}`;
    this.whatsappShareLink = `https://api.whatsapp.com/send?text=Check out this product! ${this.productUrl}`;
  }

  private rearrangeMediaGallery(): void {
    const formattedMediaGallery: { url: string; isVideo: boolean }[] = [];
    const images = this.productGallery
      .filter((url) => !isVideoFromUrl(url))
      .map((item) => ({ url: item, isVideo: false }));
    const videos = this.productGallery
      .filter((url) => isVideoFromUrl(url))
      .map((item) => ({ url: item, isVideo: true }));
    formattedMediaGallery.push(images[0]);
    formattedMediaGallery.push(...videos);
    formattedMediaGallery.push(...images.slice(1));
    this.mediaGallery = formattedMediaGallery;
    this.mediaGalleryHasVideos = videos.length > 0;
  }

  ngAfterViewInit(): void {
    this.resizeAdditionalImages();
  }

  @HostListener('window:resize', ['$event'])
  resizeAdditionalImages(event?: any): void {
    const additionalImagesContainerElement = document.getElementById('additional-images-container');
    if (additionalImagesContainerElement) {
      this.additionalImagesContainerWidth = additionalImagesContainerElement.offsetWidth;

      this.additionalImagesButtonSize = (this.additionalImagesContainerWidth - 30) / 4;
      this.thumbnailImagesTranslationOffset = this.additionalImagesButtonSize + 10;

      additionalImagesContainerElement.style.height = `${this.additionalImagesButtonSize}px`;

      const additionalImagesArrowRight = document.getElementById('thumbnail-arrow-right');
      const additionalImagesArrowLeft = document.getElementById('thumbnail-arrow-left');

      if (additionalImagesArrowRight && additionalImagesArrowLeft) {
        additionalImagesArrowRight.style.top = `${
          (this.additionalImagesButtonSize - additionalImagesArrowRight.offsetHeight) / 2
        }px`;
        additionalImagesArrowLeft.style.top = `${
          (this.additionalImagesButtonSize - additionalImagesArrowLeft.offsetHeight) / 2
        }px`;
      }

      const childNodes = additionalImagesContainerElement.children as HTMLCollectionOf<HTMLElement>;
      for (const key in childNodes) {
        if (key !== 'length' && key !== 'item' && key !== 'namedItem') {
          childNodes[key].style.width = `${this.additionalImagesButtonSize}px`;
          childNodes[key].style.height = `${this.additionalImagesButtonSize}px`;
        }
      }
    }
  }

  onClickLeft(): void {
    if (this.productImgIdx > 0) {
      this.productImgIdx--;
    }
  }

  onClickRight(): void {
    if (this.productImgIdx < this.productGallery.length - 1) {
      this.productImgIdx++;
    }
  }

  onClickThumbnailArrow(direction: string): void {
    const overflowSteps = this.productGallery.length - 4;
    const childNodes = document.getElementById('additional-images-container')!
      .children as HTMLCollectionOf<HTMLElement>;

    if (direction === 'right') {
      if (this.imgCarouselIdx + 1 <= overflowSteps) {
        this.imgCarouselIdx++;
      }
    } else if (direction === 'left') {
      if (this.imgCarouselIdx - 1 >= 0) {
        this.imgCarouselIdx--;
      }
    }

    const translation = `${this.imgCarouselIdx * this.thumbnailImagesTranslationOffset}px`;

    for (const key in childNodes) {
      if (key !== 'length' && key !== 'item' && key !== 'namedItem') {
        childNodes[key].style.transform = `translateX(-${translation})`;
      }
    }
  }

  onSelectImage(index: number): void {
    this.productImgIdx = index;
  }

  onDownloadImages(): void {
    const images = this.productGallery
      .filter((url) => !isVideoFromUrl(url))
      .map((item) => ({ url: removeImageSizeFromURL(item), isVideo: false }));
    const jszip = new JSZip();
    let count = 0;
    const productSKU = this.basicInfo.productId;
    const zipFilename = `${productSKU}.zip`;
    images.forEach((image) => {
      const fileExtension = image.url.split('.').pop();
      JSZipUtils.getBinaryContent(image.url, (err: Error, data: ArrayBuffer) => {
        if (err) {
          throw err;
        }
        jszip.file(`${productSKU}/${count + 1}.${fileExtension}`, data, { binary: true });
        count++;
        if (count === images.length) {
          jszip.generateAsync({ type: 'blob' }).then((content: Blob) => {
            saveAs(content, zipFilename);
          });
        }
      });
    });
    this.downloadedImages.emit();
  }

  onDownloadVideos(): void {
    const videos = this.productGallery
      .filter((url) => isVideoFromUrl(url))
      .map((item) => ({ url: item, isVideo: true }));
    videos.forEach((item, index) => {
      const link = document.createElement('a');
      link.href = item.url;
      link.download = '';
      setTimeout(() => {
        link.click();
        link.remove();
      }, index * 1000);
    });
    this.downloadedVideos.emit();
  }

  onReviewProduct(): void {
    this.reviewProduct.emit();
  }

  onCreativesClicked(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'request_creative_button_clicked',
      payload: {
        sku: this.basicInfo.productId,
      },
    });
    this._router.navigate([this._appURLs.CREATIVES_URL], {
      queryParams: {
        id: this.basicInfo.productId,
        name: this.basicInfo.productName,
      },
    });
  }

  enablePriceButtons(value: boolean, type?: string): void {
    if (!value) {
      this._setProductCheckoutPrice();
    } else {
      this.priceInputEnabled = true;
    }
    this.isCheckoutPriceButtonEnabled = value;
  }

  checkCreativesEnabled(): void {
    const selectedCountry = country.code.toLocaleLowerCase();
    const isUserFeatureExist = this._checkUserFeatureExistsUseCase.execute(
      `${CREATIVE_REQUEST_FEATURE}${selectedCountry}`,
    );
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.CREATIVES_FEATURE).subscribe((flag) => {
      this.isCreativesEnabled = flag && isUserFeatureExist;
    });
  }

  private _checkCheckoutEnabled(): void {
    const selectedCountry = country.code;
    this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.WEB_CUSTOM_CHECKOUT).subscribe({
      next: (attribute) => {
        this.shouldShowCustomCheckout = featureAttributeAssign(attribute, user.id, selectedCountry);
        if (this.shouldShowCustomCheckout) {
          this.getProductCheckoutDetail();
          this._checkLandingv2Enabled();
        }
      },
    });
  }

  private _checkLandingv2Enabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_CUSTOM_LANDING_DESC).subscribe({
      next: (attribute) => {
        this.shouldShowProductCheckoutV2 = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  getProductCreativesEligibility(): void {
    this._getProductCreativesEligibilityUseCase
      .execute(this.basicInfo.productId)
      .subscribe((res) => {
        this.isProductCreativesAllowed = res.isAllowed;
      });
  }

  getProductCheckoutDetail(): void {
    this._getProductCheckoutDetailUseCase
      .execute(this.basicInfo.productId)
      .pipe(
        finalize(() => {
          this._setProductCheckoutPrice();
          this.isCheckoutLoading = false;
        }),
      )
      .subscribe({
        next: (checkout) => {
          this.productCheckoutDetail = checkout;
          this.productCheckoutDetailEmit.emit(this.productCheckoutDetail);
          if (this.isInitialCall) {
            this._setProductGallery();
          }
          this.landingStatus = this.productCheckoutDetail.active;
          this.imageLinks = this.productCheckoutDetail.productPicturesURLs;
          this.setSliderValue(this.landingStatus, true);
        },
      });
  }

  createProductCheckout(): void {
    this.isCheckoutLoading = true;
    const params: ProductCheckoutCreateModel = {
      productId: this.basicInfo.productId,
      price: this.checkoutAmount,
      country: this.basicInfo.country,
    };
    this._createProductCheckoutUseCase.execute(params).subscribe({
      next: (checkout) => {
        this._logMixpanelEventUseCase.execute({
          eventName: PRODUCT_CREATE_CHECKOUT_LINK,
          payload: params,
        });
        this._toastrService.success(
          this._translateService.instant(
            'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.CREATE_LINK_SUCCESS',
          ),
        );
        this.isInitialCall = true;
        this.getProductCheckoutDetail();
        this.isCheckoutPriceButtonEnabled = false;
      },
      error: (err) => {
        this._logMixpanelEventUseCase.execute({
          eventName: PRODUCT_CREATE_CHECKOUT_LINK_ERROR,
          payload: params,
        });
        this._handleCheckoutError(err);
      },
    });
  }

  private _setProductGallery(): void {
    if (this.shouldShowProductCheckoutV2) {
      const mediaLinks = this.mediaGallery.slice(0, 5).map((x) => x.url);
      this.onUpdateImages(mediaLinks);
    }
  }

  onUpdateImages(event: string[]): void {
    this.imageLinks = event;
    this.updateProductCheckout();
  }

  updateProductCheckout(data?: {
    pixelId?: string;
    isAddressFormLong?: boolean;
    description?: string;
  }): void {
    if (this.checkoutAmount < this.basicInfo.productPrice) {
      this._toastrService.error(
        this._translateService.instant(
          'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.INVALID_PRICE',
        ),
      );
      return;
    }
    const params: ProductCheckoutUpdateModel = {
      uuid: this.productCheckoutDetail.id,
      price: this.checkoutAmount,
      productName: this.basicInfo.productName,
      active: this.isInitialCall ? true : this.landingStatus,
      productPicturesURLs: this.imageLinks,
      pixelId: data?.pixelId || this.productCheckoutDetail.pixelId,
      isAddressFormLong: data?.isAddressFormLong || this.productCheckoutDetail.isAddressFormLong,
      description: data?.description || this.productCheckoutDetail.description,
    };

    this._updateProductCheckoutDetailUseCase.execute(params).subscribe({
      next: (checkout) => {
        this._logMixpanelEventUseCase.execute({
          eventName: PRODUCT_UPDATE_CHECKOUT_LINK,
          payload: params,
        });
        if (!this.isInitialCall) {
          this._toastrService.success(
            this._translateService.instant(
              'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.UPDATE_LINK_SUCCESS',
            ),
          );
        }
        this.isInitialCall = false;
        this.getProductCheckoutDetail();
        this.isCheckoutPriceButtonEnabled = false;
      },
      error: (err) => {
        this._logMixpanelEventUseCase.execute({
          eventName: PRODUCT_UPDATE_CHECKOUT_LINK_ERROR,
          payload: params,
        });
        this._handleCheckoutError(err);
      },
    });
  }

  private _handleCheckoutError(err: any): void {
    let errorMessage;
    if (err.error.errorCode === 'invalid_price') {
      errorMessage = this._translateService.instant(
        'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.INVALID_PRICE',
      );
    } else {
      errorMessage = err.error.description;
    }
    this._toastrService.error(errorMessage);
  }

  redirectToProductCheckout(): void {
    this.customizeLanding.emit(true);
  }

  copyCheckoutLink(): void {
    const customerUrl = `${environment.CUSTOMER_ORDER_URL}`;
    this._clipboard.copy(customerUrl + this.productCheckoutDetail.id);
    this._logMixpanelEventUseCase.execute({
      eventName: PRODUCT_COPY_CHECKOUT_LINK,
      payload: customerUrl,
    });
    this._toastrService.success(
      this._translateService.instant('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.LINK_COPIED'),
    );
  }

  redirectToCheckoutLink(): void {
    const customerUrl = `${environment.CUSTOMER_ORDER_URL}${this.productCheckoutDetail.id}`;
    this._logMixpanelEventUseCase.execute({
      eventName: PRODUCT_REDIRECT_CHECKOUT_LINK,
      payload: customerUrl,
    });
    window.open(customerUrl, '_blank');
  }

  private _setProductCheckoutPrice(): void {
    this.checkoutAmount = this.productCheckoutDetail
      ? this.productCheckoutDetail.productPrice
      : this.basicInfo.productPrice;
  }

  setSliderValue(event: boolean, isInitial?: boolean) {
    if (event) {
      this._logMixpanelEventUseCase.execute({ eventName: PRODUCT_CHECKOUT_LINK_ENABLE });
      this.statusTranslationKey = this._translateService.instant(
        'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.STATUS_ON',
      );
    } else {
      this._logMixpanelEventUseCase.execute({ eventName: PRODUCT_CHECKOUT_LINK_DISABLE });
      this.statusTranslationKey = this._translateService.instant(
        'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.STATUS_OFF',
      );
    }
    if (!isInitial) {
      this.updateProductCheckout();
    }
  }
}
