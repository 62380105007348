<section class="product-gallery">
  <section class="main-img-container">
    <button
      class="chevron-button chevron-left clickable"
      *ngIf="mediaGallery.length !== 1"
      (click)="onClickLeft()"
      [disabled]="productImgIdx === 0"
    >
      <img src="assets/img/left-chevron.svg" alt="left-chevron-icon" />
    </button>
    <div class="main-media">
      <ng-container *ngFor="let mediaItem of mediaGallery; index as itemIdx">
        <img
          class="main-image"
          [src]="mediaItem?.url"
          *ngIf="itemIdx === productImgIdx && !mediaItem?.isVideo"
        />
        <video
          class="main-image"
          [src]="mediaItem.url"
          *ngIf="itemIdx === productImgIdx && mediaItem?.isVideo"
          controls="true"
          name="Product video"
          type="video/mp4"
          preload="metadata"
        ></video>
      </ng-container>
    </div>
    <button
      class="chevron-button chevron-right clickable"
      *ngIf="mediaGallery.length !== 1"
      (click)="onClickRight()"
      [disabled]="productImgIdx === mediaGallery.length - 1"
    >
      <img src="assets/img/right-chevron.svg" alt="right-chevron-icon" />
    </button>
  </section>

  <section class="additional-images-carousel" *ngIf="mediaGallery.length > 1">
    <div class="additional-images-container" id="additional-images-container">
      <button
        class="image-select-button clickable"
        *ngFor="let imageUrl of mediaGallery; index as idx"
        [ngClass]="{ 'selected-image': idx === productImgIdx }"
        (click)="onSelectImage(idx)"
      >
        <img *ngIf="!imageUrl?.isVideo" class="image-thumbnail" [src]="imageUrl?.url" />
        <div *ngIf="imageUrl?.isVideo" class="video-thumbnail-container">
          <video
            class="image-thumbnail"
            name="Product video"
            [src]="imageUrl.url"
            type="video/mp4"
            preload="metadata"
          ></video>
          <img src="assets/img/play-circle.svg" class="video-thumbnail-image" />
        </div>
      </button>
    </div>
    <button
      class="thumbnail-arrow thumbnail-arrow-left clickable"
      id="thumbnail-arrow-left"
      *ngIf="mediaGallery.length > 4"
      (click)="onClickThumbnailArrow('left')"
      [disabled]="imgCarouselIdx === 0"
    >
      <img src="assets/img/left-chevron.svg" alt="left-chevron-icon" />
    </button>
    <button
      class="thumbnail-arrow thumbnail-arrow-right clickable"
      id="thumbnail-arrow-right"
      *ngIf="mediaGallery.length > 4"
      (click)="onClickThumbnailArrow('right')"
      [disabled]="imgCarouselIdx === mediaGallery.length - 4"
    >
      <img src="assets/img/right-chevron.svg" alt="right-chevron-icon" />
    </button>
  </section>
  <button class="download-button clickable body2--bold" (click)="onDownloadImages()">
    <img class="icon-style" src="{{ assetsPath + 'images-icon.svg' }}" />
    {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_GALLERY.DOWNLOAD_PRODUCT_IMAGES' | translate }}
  </button>
  <button
    *ngIf="mediaGalleryHasVideos"
    class="download-button clickable body2--bold"
    (click)="onDownloadVideos()"
  >
    <img class="icon-style" src="{{ assetsPath + 'videos-icon.svg' }}" />
    {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_GALLERY.DOWNLOAD_PRODUCT_VIDEOS' | translate }}
  </button>
  <div *ngIf="isCreativesEnabled">
    <button
      class="creatives-button body2--bold"
      (click)="onCreativesClicked()"
      *ngIf="isProductCreativesAllowed"
    >
      <img class="icon-style" src="{{ assetsPath + 'creatives-icon.svg' }}" />
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_GALLERY.CREATIVES' | translate }}
    </button>
    <p class="not-allowed-creatives" *ngIf="!isProductCreativesAllowed">
      <img class="icon-style" src="{{ assetsPath + 'lock.svg' }}" />
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_GALLERY.CREATIVES_NOT_ALLOWED' | translate }}
    </p>
  </div>
</section>
<section class="review-product-section">
  <button
    class="action-button review-product-button clickable body2--bold"
    (click)="onReviewProduct()"
  >
    <img src="{{ assetsPath + 'star.svg' }}" />
    {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.WRITE_A_REVIEW' | translate }}
  </button>
</section>
<section class="product-checkout" *ngIf="shouldShowCustomCheckout">
  <div class="product-checkout-container">
    <ng-container *ngIf="!hasMultipleVariants; else multipleVariants">
      <div class="announcement-new">
        <p class="body2--regular">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.NEW_ANNOUNCEMENT' | translate }}
        </p>
      </div>
      <div class="body-section">
        <p class="body1--bold title">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.TITLE' | translate }} 🚀
        </p>
        <p class="body2--regular description">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.DESCRIPTION' | translate }}
        </p>
        <div class="price-container">
          <label class="caption1--medium">{{
            'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.PRICE_LABEL' | translate
          }}</label>
          <div class="input-value">
            <span class="caption1--bold currency-prefix">{{
              'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.SAR_CURRENCY' | translate
            }}</span>
            <input
              [disabled]="!landingStatus && productCheckoutDetail"
              type="number"
              [(ngModel)]="checkoutAmount"
              (ngModelChange)="enablePriceButtons(true)"
              (click)="!productCheckoutDetail ? enablePriceButtons(true) : null"
            />
            <div class="btn-container caption1--regular">
              <button
                class="price-action price-action-primary action-create"
                [ngClass]="{ disabled: isCheckoutLoading }"
                *ngIf="!productCheckoutDetail && priceInputEnabled"
                (click)="createProductCheckout()"
                type="button"
                [disabled]="isCheckoutLoading"
              >
                {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.CREATE' | translate }}
              </button>
              <ng-container *ngIf="productCheckoutDetail && isCheckoutPriceButtonEnabled">
                <button class="price-action action-cancel" (click)="enablePriceButtons(false)">
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.CANCEL' | translate }}
                </button>
                <button
                  class="price-action price-action-primary action-apply"
                  (click)="updateProductCheckout()"
                >
                  {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.APPLY' | translate }}
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <ng-container *ngIf="productCheckoutDetail">
          <div class="action-btn-container">
            <button class="action-btn transparent-btn" (click)="copyCheckoutLink()">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.COPY_LINK' | translate }}
              <i class="icon icon-copy-2"></i>
            </button>
            <button class="action-btn transparent-btn" (click)="redirectToCheckoutLink()">
              <i class="icon icon-redirect"></i>
            </button>
            <button
              class="action-btn body1--regular ghost-btn"
              (click)="redirectToProductCheckout()"
            >
              <i class="icon icon-edit"></i>
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.CUSTOMIZE_PIXEL' | translate }}
            </button>
          </div>
          <div class="info-section">
            <img src="/assets/img/info-circle.svg" alt="" />
            <p class="caption2--medium">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.TEST_INFO' | translate }}
            </p>
          </div>
          <div class="image-section">
            <app-product-checkout-images
              [imageUrlList]="productCheckoutDetail?.productPicturesURLs"
              (uploadedImagesURL)="onUpdateImages($event)"
              (deletedImageIndex)="onUpdateImages($event)"
            ></app-product-checkout-images>
          </div>
          <div class="toggle-section">
            <p class="caption1--bold content-main">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.LANDING_STATUS' | translate }}
            </p>
            <p class="align-status">
              <mat-slide-toggle
                (change)="setSliderValue($event.checked)"
                [(ngModel)]="landingStatus"
                labelPosition="before"
                >{{ statusTranslationKey }}</mat-slide-toggle
              >
            </p>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #multipleVariants>
      <div class="no-landing-icon">
        <img src="assets/img/auth/danger-white.svg" />
      </div>
      <div class="no-landing-body">
        <p class="heading2--bold">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.NO_SUPPORT_TITLE' | translate }}
        </p>
        <p class="body2--regular details">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.NO_SUPPORT_DETAILS' | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</section>
